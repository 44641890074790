import React from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';

import Layout from '../layouts';
import Container from '../components/Container';
import Row from '../components/Container/row';
import Col from '../components/Container/column';
import Breadcrumbs from '../components/Breadcrumbs';
import Sidebar from '../components/Sidebar';
import SidebarCta from '../components/Sidebar/cta';

import '../components/Subpage/style.css';

import loadComponents from '../components/Loadable';
import Share from '../components/Share';
import RelatedLinks from '../components/RelatedLinks';

const Form = loadComponents('form');
const SubpageHeader = loadComponents('subpage-header');

export default function PageTemplate({ location, data }) {
  const prevPage = location.state && location.state.prevPage;
  const { wpPage, parent } = data;

  const pageTitle =
    wpPage.seo && wpPage.seo.title ? wpPage.seo.title : wpPage.title;
  const featuredImage =
    wpPage?.images?.featuredImage && wpPage?.images?.featuredImage?.localFile;

  const breadcrumbData =
    parent && parent.title && parent.link && parent?.link !== '/'
      ? [
          { label: 'Home', url: '/' },
          { label: parent?.title, url: parent?.link },
          { label: wpPage.title, url: wpPage.link },
        ]
      : [
          { label: 'Home', url: '/' },
          { label: wpPage.title, url: wpPage.link },
        ];

  return (
    <Layout
      title={pageTitle}
      seo={wpPage.seo}
      location={wpPage.link}
      prevPage={prevPage}
      padding="0 0 60px"
    >
      <article>
        <SubpageHeader
          image={featuredImage?.childImageSharp?.gatsbyImageData}
          title={wpPage.title}
          date={wpPage.date}
        />
        <Container>
          <Row justify="space-between" rowGap="45px">
            <Col xl={{ width: `65%` }} className="contentOuter">
              <Breadcrumbs data={breadcrumbData} />
              <section className="pageContent page">
                {wpPage.content && (
                  <div className="content">{parse(wpPage.content)}</div>
                )}
              </section>
              {wpPage.formBuilder && wpPage.formBuilder.formGroup && (
                <section>
                  <Form
                    fields={wpPage.formBuilder.formGroup}
                    name={wpPage.link}
                    style={{
                      marginTop: `60px`,
                    }}
                  />
                </section>
              )}
              <div className="postFooter" style={footerStyle}>
                <Share title={wpPage.title} />
                {wpPage?.subpageLayout?.relatedLinks?.links?.length > 0 && (
                  <RelatedLinks
                    data={wpPage?.subpageLayout?.relatedLinks?.links}
                  />
                )}
              </div>
            </Col>
            <Col
              xxl={{ width: `30%` }}
              xl={{ width: `35%` }}
              className="pageSidebar"
            >
              {wpPage.subpageLayout?.sidebar?.length > 0 && (
                <Sidebar data={wpPage.subpageLayout.sidebar} />
              )}
              {wpPage.sidebarCta.showCta && (
                <SidebarCta
                  hasMenu={wpPage.subpageLayout.sidebar?.length > 0}
                  link={wpPage.sidebarCta.link}
                />
              )}
            </Col>
          </Row>
        </Container>
      </article>
    </Layout>
  );
}

const footerStyle = {
  margin: `60px 0 0 0`,
};

export const pageQuery = graphql`
  query ($id: String!, $parent: Int!) {
    wpPage(id: { eq: $id }) {
      title
      content
      link
      date(formatString: "Do MMMM YYYY")
      images {
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, height: 420, layout: CONSTRAINED)
            }
          }
        }
      }
      ...PageSeo
      ...FormBuilder
      sidebarCta {
        showCta
        link
      }
      subpageLayout {
        relatedLinks {
          links {
            link
            label
            title
          }
        }
        sidebar {
          header
          links {
            label
            link
            title
          }
        }
        footerColumns {
          title
          text
          link
          action
        }
      }
    }
    parent: wpPage(databaseId: { eq: $parent }) {
      title
      link
    }
    wp {
      ...DefaultPageLayout
    }
  }
`;
